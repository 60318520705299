@import "../variables.module";

.cta {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 33px;

  .img {
    transform: translateY(-1px);
  }

  .number {
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    font-style: italic;
    color: white !important;

    text-shadow:
      -0.9px -0.9px 0 #5b656d,
      1.3px -0.2px 0px #2a99f7,
      0.9px -0.9px 0 #5b656d,
      0.9px 0.9px 0 #5b656d,
      1px -0.7px 0px #2a99f7,
      0px 4.911px 4.911px rgba(0, 0, 0, 0.25),
      0px 0px 34.242px #0077db;

    -webkit-text-stroke-width: 0.9822099208831787;
    -webkit-text-stroke-color: #000;

    background: linear-gradient(181deg, #fff 14.35%, #f5f5f5 99%);
    background-clip: text;
    -webkit-background-clip: text;
  }

  .container_text {
    display: flex;
    gap: 0.25rem;
  }

  .text {
    font-size: 12px;
    color: white;
    text-wrap: nowrap;
    padding-left: 22px;
    text-transform: none;

    hr {
      width: 100%;
      height: 1px;
      border: 0;
      border-radius: 6px;
      background: linear-gradient(
        89.75deg,
        rgba(230, 189, 119, 0) 0.52%,
        #e6bd77 101.1%
      );
      margin: 0;
      margin-top: 1px;
    }
  }
  .countdown_container {
    position: relative;
    display: flex;
    padding: 5px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background-color: #222846; /* Adjust as necessary */
    margin-left: auto;
  }

  .countdown_container::before,
  .countdown_container::after {
    content: "";
    position: absolute;
    border-radius: 6px;
  }

  .countdown_container::before {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(
      180deg,
      rgba(190, 255, 86, 0) 0%,
      rgba(190, 255, 86, 1) 100%
    );
    z-index: -1;
  }

  .countdown_container::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #222846;
    border-radius: inherit;
    z-index: -2;
  }

  .countdown {
    position: relative;
    font-size: 10px;
    font-weight: 700;
    font-style: italic;
    color: #ffffff;

    text-shadow:
      -0.9px -0.9px 0 #5b656d,
      1.3px -0.2px 0px #2a99f7,
      0.9px -0.9px 0 #5b656d,
      0.9px 0.9px 0 #5b656d;

    line-height: 100%;
    letter-spacing: 0.165px;
    text-transform: uppercase;
    background: linear-gradient(
      180deg,
      rgba(190, 255, 86, 0) 0%,
      rgba(190, 255, 86, 1) 100%
    );
    background-clip: text;
  }
}
